interface Config {
  env: "development" | "production";
  apiUrl: string;
}

const devConfig: Config = {
  env: "development",
  apiUrl: "http://localhost:3030/",
};

const prodConfig: Config = {
  env: "production",
  apiUrl: "https://api.dashark.software/",
};

export const config =
  process.env.REACT_APP_ENV === "production" ? prodConfig : devConfig;
