import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { isLogged, logout } from "../../services/auth";
import LoginButton from "../User.tsx/LoginButton";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getProfile } from "../../services/user";
import { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";

export default function User() {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>();
  const [open, setOpen] = useState(false);

  const { data: user } = useQuery({
    queryKey: ["user"],
    queryFn: () =>
      getProfile().catch((error) => {
        if (error.response.status === 401) {
          setError("Please login to see your expenses");
        }
      }),
  });

  if ((!isLogged() && !user) || error) {
    return <LoginButton />;
  }

  const toggleDrawer = (open: boolean) => () => {
    setOpen(open);
  };

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)}>
        <Avatar sx={{ bgcolor: "#7D76CF" }}>
          {user?.name[0].toUpperCase()}
        </Avatar>
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        <div>
          <Box role="presentation" onClick={toggleDrawer(false)}>
            <ListItem key={"profie"}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={user?.name} secondary={user?.email} />
            </ListItem>
            <Divider />
            <ListItem key={"settings"} disablePadding>
              <ListItemButton disabled={true}>
                <ListItemIcon>
                  <SettingsIcon fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={"Settings"} />
              </ListItemButton>
            </ListItem>

            <ListItem key={"logout"} disablePadding>
              <ListItemButton
                onClick={() => {
                  logout(queryClient);
                }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </Box>
        </div>
      </Drawer>
    </div>
  );
}
