import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CategoryExpense from "./CategoryExpense";
import { useState } from "react";
import { ExpenseType } from "../../enums/Expense";
import { getExpensesGrouped } from "../../services/expense";
import { useQuery } from "@tanstack/react-query";
import LoginDialog from "../Modals/LoginModal";

type GridExpensesProps = {
  date: string;
};

export default function GridExpenses({ date }: GridExpensesProps) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();

  const handleClose = () => {
    setOpen(false);
  };

  const { data: expenses } = useQuery({
    queryKey: ["expensesGrouped", date],
    queryFn: () =>
      getExpensesGrouped(date).catch((error) => {
        if (error.response.status === 401) {
          setError("Please login to see your expenses");
          setOpen(true);
        }
      }),
  });

  if (!expenses || error) {
    return <LoginDialog open={open} handleClose={handleClose} />;
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} margin={1}>
          <Grid xs={12} md={2.4}>
            <CategoryExpense
              title="Ingresos"
              amount={expenses.INGRESS.reduce((total, expense) => {
                return total + expense.amount;
              }, 0)}
              cardType={ExpenseType.INGRESS}
              data={expenses.INGRESS}
            />
          </Grid>
          <Grid xs={12} md={2.4}>
            <CategoryExpense
              title="Necesarios"
              amount={expenses.NEEDS.reduce(
                (total, expense) => total + expense.amount,
                0
              )}
              cardType={ExpenseType.NEEDS}
              data={expenses.NEEDS}
            />
          </Grid>
          <Grid xs={12} md={2.4}>
            <CategoryExpense
              title="Gastos"
              amount={expenses.WANTS.reduce(
                (total, expense) => total + expense.amount,
                0
              )}
              cardType={ExpenseType.WANTS}
              data={expenses.WANTS}
            />
          </Grid>
          <Grid xs={12} md={2.4}>
            <CategoryExpense
              title="Ahorros"
              amount={expenses.SAVING.reduce(
                (total, expense) => total + expense.amount,
                0
              )}
              cardType={ExpenseType.SAVING}
              data={expenses.SAVING}
            />
          </Grid>
          <Grid xs={12} md={2.4}>
            <CategoryExpense
              title="Inversiones"
              amount={expenses.INVESTMENT.reduce(
                (total, expense) => total + expense.amount,
                0
              )}
              cardType={ExpenseType.INVESTMENT}
              data={expenses.INVESTMENT}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}
