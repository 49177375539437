import { Button } from "@mui/material";
import React from "react";
import LoginDialog from "../Modals/LoginModal";

export default function LoginButton() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen}>Login</Button>
      <LoginDialog open={open} handleClose={handleClose} />
    </React.Fragment>
  );
}
