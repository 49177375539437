import { getAuth } from "../client/axiosClient";
import { TargetExpense } from "../types/Expense";
import { User } from "../types/User";

export const getProfile = () => {
  return getAuth<User>("user/profile");
};

export const getTarget = (): Promise<TargetExpense> => {
  return getAuth("user/target");
};
