import { Dispatch, SetStateAction } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import styled from "styled-components";

const StyledMonthPicker = styled.div`
  margin-left: auto;
  background-color: #f3f4f6;
  .MuiInputBase-input {
    padding: 0.5rem;
  }
`;

type MonthPickerProps = {
  setDate: Dispatch<SetStateAction<string>>;
};

export default function MonthPicker({ setDate }: MonthPickerProps) {
  const handleChangeDate = (newDate: Date) => {
    setDate(newDate.toISOString().split("T")[0]);
  };

  return (
    <StyledMonthPicker onClick={(event) => event.stopPropagation()}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["month"]}
          defaultValue={dayjs(new Date())}
          onAccept={(newDate) => handleChangeDate(newDate!.toDate())}
        />
      </LocalizationProvider>
    </StyledMonthPicker>
  );
}
