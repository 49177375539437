import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import RestoreIcon from "@mui/icons-material/Restore";
import DeleteExpenseDialog from "./DeleteExpenseDialog";
import { Expense } from "../../types/Expense";
import { formatDate } from "../../utils/date";

interface CardExpenseProps {
  expense: Expense;
}

export default function CardExpense({ expense }: CardExpenseProps) {
  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardHeader
        title={expense.title}
        action={<DeleteExpenseDialog expense={expense} />}
      />
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
          }}
        >
          $ {expense.amount}
        </Typography>
        <Stack direction={"row"} className="CardBottom">
          <CalendarTodayIcon fontSize="small" />
          <Typography>{formatDate(expense.date)}</Typography>
          {expense.recurrent ? (
            <RestoreIcon fontSize="small" sx={{ marginLeft: "auto" }} />
          ) : (
            ""
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
