import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import MonthPicker from "../MonthPicker";
import SummaryGrid from "./SummaryGrid";
import styled from "styled-components";
import AutoAwesomeMosaicRoundedIcon from "@mui/icons-material/AutoAwesomeMosaicRounded";
import { Dispatch, SetStateAction } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAccordion = styled(Accordion)`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;

  .MuiAccordionSummary-expandIconWrapper {
    order: -1;
  }

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .MuiAccordionDetails-root {
    padding: 0px;
    .grid {
      .MuiGrid2-root {
        margin: 0px;
      }
    }
  }
`;
type summaryProps = {
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
};

export default function Summary({ date, setDate }: summaryProps) {
  return (
    <div>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AutoAwesomeMosaicRoundedIcon />
          <Typography variant="h6" fontWeight={550}>
            Objetivo
          </Typography>
          <MonthPicker setDate={setDate} />
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid">
            <SummaryGrid date={date} />
          </div>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
}
