import {Tab, Tabs} from "@mui/material";
import React from "react";

export default function TopBarNav() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return(
        <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            TabIndicatorProps={{style: {backgroundColor: '#7D76CF', height: '3px'}}}
            sx={{
                marginLeft: 5,
            }}
        >
            <Tab value={0} label="Dashboard" />
            <Tab value={1} label="Graficos" disabled={true} />
            <Tab value={2} label="Archivos" disabled={true} />
        </Tabs>
    )
}
