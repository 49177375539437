import { getAuth, httpDeleteAuth, postAuth } from "../client/axiosClient";
import { Expense, ExpenseGrouped, createExpense } from "../types/Expense";

export const getExpensesGrouped = (date: string) => {
  return getAuth<ExpenseGrouped>(`expense/grouped/${date}`);
};

export const postExpense = (expense: createExpense) => {
  return postAuth<Expense>("expense", expense);
};

export const deleteExpense = (id: string) => {
  return httpDeleteAuth<Expense>(`expense/${id}`);
};

export const getSummary = (date: string) => {
  return getAuth(`expense/summary/${date}`);
};
