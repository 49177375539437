import { AppBar, Container, Toolbar, Typography } from "@mui/material";
import TopBarNav from "./TopBarNav";
import User from "./User";

export default function TopBar() {
  return (
    <AppBar
      position={"static"}
      className={"TopBar"}
      sx={{
        backgroundColor: "#ffffff",
        boxShadowBottom: 1,
      }}
    >
      <Container fixed maxWidth="xl">
        <Toolbar disableGutters>
          <Typography variant={"h4"}>DaShark</Typography>
          <TopBarNav />
          <div className="to-right">
            <User />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
