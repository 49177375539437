import { Button } from "@mui/material";
import { config } from "../../config/config";
import GoogleIcon from "@mui/icons-material/Google";

export default function GoogleButton() {
  return (
    <Button
      variant="outlined"
      color="primary"
      href={config.apiUrl + "auth/google"}
      startIcon={<GoogleIcon />}
    >
      Login with Google
    </Button>
  );
}
