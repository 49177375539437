export function getKeyByValue<T extends Record<string, string | number>>(
  enumObj: T,
  value: T[keyof T]
): keyof T | null {
  const keys = Object.keys(enumObj) as Array<keyof T>;
  for (const key of keys) {
    if (enumObj[key] === value) {
      return key;
    }
  }
  return null;
}
