export function toPercentage(value: number, total: number): number {
  console.log(total, total === 0);
  if (total === 0 || isNaN(total)) {
    return 0;
  }
  return Math.round((value / total) * 100);
}

export function toPercentageFloat(value: number): number {
  return value * 100;
}
