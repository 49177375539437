import { Dialog, DialogContent } from "@mui/material";
import GoogleButton from "../OAuth/GoogleButton";

type Props = {
  open: boolean;
  handleClose: () => void;
};

export default function LoginDialog(props: Props) {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogContent>
        <GoogleButton />
      </DialogContent>
    </Dialog>
  );
}
