import Grid2 from "@mui/material/Unstable_Grid2";
import SummaryCard from "./SummaryCard";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getSummary } from "../../services/expense";
import { TargetExpense } from "../../types/Expense";
import { getTarget } from "../../services/user";

interface SummaryDataType {
  WANTS: number;
  NEEDS: number;
  INGRESS: number;
  INVESTMENT: number;
  SAVING: number;
}

const defaultData = {
  WANTS: 0,
  NEEDS: 0,
  INGRESS: 0,
  INVESTMENT: 0,
  SAVING: 0,
};

const defaultTarget = {
  need: 0,
  wants: 0,
  savings: 0,
  invest: 0,
};

type summaryProps = {
  date: string;
};

export default function SummaryGrid({ date }: summaryProps) {
  const [summary, setSummary] = useState<SummaryDataType>(defaultData);
  const [target, setTarget] = useState<TargetExpense>(defaultTarget);

  useQuery({
    queryKey: ["summary", date],
    queryFn: () => {
      getSummary(date)
        .then((data) => {
          setSummary(data as SummaryDataType);
        })
        .catch((_) => setSummary(defaultData));
    },
  });

  useQuery({
    queryKey: ["target"],
    queryFn: () => {
      getTarget()
        .then((data) => {
          setTarget(data);
        })
        .catch((_) => setTarget(defaultTarget));
    },
  });

  console.log(target);

  return (
    <Grid2 container spacing={2} margin={2}>
      <Grid2 xs={3}>
        <SummaryCard
          cardType={"Neceserio"}
          title={"Neceserio"}
          amount={summary.NEEDS ? summary.NEEDS : 0}
          target={target.need}
          ingress={summary.INGRESS}
        ></SummaryCard>
      </Grid2>
      <Grid2 xs={3}>
        <SummaryCard
          cardType={"Gasto"}
          title={"Gasto"}
          amount={summary.WANTS ? summary.WANTS : 0}
          target={target.wants}
          ingress={summary.INGRESS}
        ></SummaryCard>
      </Grid2>
      <Grid2 xs={3}>
        <SummaryCard
          cardType={"Ahorro"}
          title={"Ahorro"}
          amount={summary.SAVING ? summary.SAVING : 0}
          target={target.savings}
          ingress={summary.INGRESS}
        ></SummaryCard>
      </Grid2>
      <Grid2 xs={3}>
        <SummaryCard
          cardType={"Inversion"}
          title={"Inversión"}
          amount={summary.INVESTMENT ? summary.INVESTMENT : 0}
          target={target.invest}
          ingress={summary.INGRESS}
        ></SummaryCard>
      </Grid2>
    </Grid2>
  );
}
