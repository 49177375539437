import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ExpenseType } from "../../enums/Expense";
import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postExpense } from "../../services/expense";
import { createExpense } from "../../types/Expense";
import { getKeyByValue } from "../../utils/enums";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CurrencyInputCustom from "../common/CurrencyInput";

const StyledDialog = styled(Dialog)`
  .fullWidth {
    width: 100%;
  }
  .MuiTypography-body1 {
    margin-bottom: 10px;
  }

  .MuiSelect-select {
    padding: 10px;
  }
  .MuiInput-root {
    padding-inline: 10px;
    padding-block: 5px;
    background-color: #f3f4f6;
    border-radius: 5px;
  }
`;

type Props = {
  type: ExpenseType;
};

export default function AddExpenseDialog(props: Props) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [typeExpense, setTypeExpense] = React.useState<ExpenseType>(props.type);

  const mutation = useMutation({
    mutationFn: (expenseToPost: createExpense) => {
      expenseToPost.description = "";
      expenseToPost.amount = amount;
      expenseToPost.recurrent = (expenseToPost.recurrent as unknown) === "on";
      expenseToPost.type = getKeyByValue(
        ExpenseType,
        expenseToPost.type
      ) as ExpenseType;
      return postExpense(expenseToPost);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expensesGrouped"] });
      queryClient.invalidateQueries({ queryKey: ["summary"] });
      setOpen(false);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen}>
        <AddIcon fontSize="medium" />
      </Button>
      <StyledDialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            mutation.mutate(formJson as createExpense);
          },
        }}
      >
        <DialogContent>
          <Grid2
            container
            spacing={3}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid2 xs={12}>
              <Typography variant="body1" fontWeight={"600"}>
                Titulo
              </Typography>
              <TextField
                autoFocus
                required
                id="title"
                name="title"
                type="text"
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true }}
                placeholder="Compra de supermercado"
              />
            </Grid2>
            <Grid2 xs={12} sm={6}>
              <Typography variant="body1" fontWeight={"600"}>
                Fecha
              </Typography>
              <TextField
                required
                id="date"
                name="date"
                type="date"
                variant="standard"
                fullWidth
                InputProps={{ disableUnderline: true }}
                defaultValue={new Date().toISOString().split("T")[0]}
              />
            </Grid2>
            <Grid2 xs={12} sm={6}>
              <Typography variant="body1" fontWeight={"600"}>
                Monto
              </Typography>
              <CurrencyInputCustom
                value={amount}
                onChange={(value) => setAmount(value)}
              />
            </Grid2>
            <Grid2 xs={12} sm={6}>
              <Typography variant="body1" fontWeight={"600"}>
                Tipo
              </Typography>
              <Select
                labelId="type"
                id="type"
                name="type"
                label="Tipo"
                value={typeExpense}
                onChange={(event) =>
                  setTypeExpense(event.target.value as ExpenseType)
                }
                fullWidth
              >
                <MenuItem value={ExpenseType.INGRESS}>Ingreso</MenuItem>
                <MenuItem value={ExpenseType.NEEDS}>Necesarios</MenuItem>
                <MenuItem value={ExpenseType.WANTS}>Gastos</MenuItem>
                <MenuItem value={ExpenseType.SAVING}>Ahorros</MenuItem>
                <MenuItem value={ExpenseType.INVESTMENT}>Inversiones</MenuItem>
              </Select>
            </Grid2>
            <Grid2 xs={12} sm={6}>
              <Typography variant="body1" fontWeight={"600"}>
                Frecuente
              </Typography>
              <Switch id="recurrent" name="recurrent"></Switch>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit">Agregar</Button>
        </DialogActions>
      </StyledDialog>
    </React.Fragment>
  );
}
