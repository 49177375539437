import "./style/SummaryCard.css";
import "./style/TopBar.css";
import "./style/Expense.css";
import "./style/Display.css";
import "@fontsource/manrope"; // Defaults to weight 400
import "@fontsource/manrope/700.css";
import TopBar from "./components/TopBar/TopBar";
import GridExpenses from "./components/Expenses/GridExpenses";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Summary from "./components/Summary/Summary";
import { useState } from "react";
import { checkCookies } from "./services/auth";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Create a client
const queryClient = new QueryClient();

function App() {
  checkCookies();
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <TopBar />
        <Summary date={date} setDate={setDate} />
        <GridExpenses date={date} />
      </div>
      {/* TODO : Use lazy load in prod */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
