import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import CardExpense from "./CardExpense";
import AddExpenseDialog from "./AddExpenseDialog";
import { ExpenseType } from "../../enums/Expense";
import { Expense } from "../../types/Expense";
import { formatCurrency } from "../../utils/currency";

interface CategoryExpenseProps {
  title: string;
  amount: number;
  cardType: ExpenseType;
  data: any;
}

export default function CategoryExpense({
  title,
  amount,
  cardType,
  data,
}: CategoryExpenseProps) {
  return (
    <Card
      style={{
        maxHeight: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader
        className={cardType}
        title={title}
        action={
          <Stack direction={"row"}>
            <Typography
              className="Amount"
              variant="h5"
              sx={{
                fontWeight: "bold",
                marginLeft: 2,
                color: "black",
              }}
            >
              ${formatCurrency(amount)}
            </Typography>
            <AddExpenseDialog type={cardType}></AddExpenseDialog>
          </Stack>
        }
        titleTypographyProps={{
          fontWeight: "bold",
        }}
      ></CardHeader>
      <CardContent
        style={{
          overflowY: "auto",
        }}
      >
        {data.map((expense: Expense) => {
          return <CardExpense key={expense.id} expense={expense}></CardExpense>;
        })}
      </CardContent>
    </Card>
  );
}
