export function checkCookies() {
  // If the cookie 'access_token' is set, set localStorage logged to true
  if (document.cookie.includes("access_token")) {
    localStorage.setItem("logged", "true");
  }
}

export function isLogged() {
  // Check if localStorage logged is true
  if (localStorage.getItem("logged") === "true") {
    return true;
  }
  // Check if the cookie 'access_token' is set
  if (document.cookie.includes("access_token")) {
    localStorage.setItem("logged", "true");
    return true;
  }
  return false;
}

export function logout(queryClient: any) {
  // Remove token from local storage
  localStorage.removeItem("token");
  // Remove logged from local storage
  localStorage.removeItem("logged");
  // Remove cookie 'access_token'
  document.cookie =
    "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  queryClient.invalidateQueries({ queryKey: ["expensesGrouped"] });
  queryClient.invalidateQueries({ queryKey: ["summary"] });
}
