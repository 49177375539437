import { Card, Typography } from "@mui/material";
import { toPercentage, toPercentageFloat } from "../../utils/percentage";

interface SummaryCardProps {
  cardType: string;
  title: string;
  amount: number;
  target: number;
  ingress: number;
}

export default function SummaryCard({
  cardType,
  title,
  amount,
  target,
  ingress,
}: SummaryCardProps) {
  return (
    <Card
      className={cardType}
      sx={{
        padding: 2,
        textAlign: "left",
      }}
    >
      <Typography
        variant={"h5"}
        sx={{
          fontWeight: "bold",
          marginTop: 1,
        }}
      >
        {title}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant={"h4"}
          sx={{
            fontWeight: "bold",
            marginTop: 2,
          }}
        >
          {toPercentage(amount, ingress)}%
        </Typography>
        <Typography>{toPercentageFloat(target)}%</Typography>
      </div>
    </Card>
  );
}
